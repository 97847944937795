<template>
  <div>
    <Table
      title-key="orderNumber"
      :actions="actions"
      :items="$store.getters['transactionV2/tableData']"
      :pagination="$store.state.transactionV2.tablePagination"
      :loading="$store.state.transactionV2.loadingTable"
      :fields="$store.getters['transactionV2/tableFields']"
      :keyword="$store.state.transactionV2.tableKeyword"
      :filter="$store.state.transactionV2.tableFilter"
      :sort="$store.state.transactionV2.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      search-placeholder="Search supporter, order number, creator"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @load="loadData"
      @sort="sortData"
      @paginate="paginate"
      @search="search"
      @download="downloadData"
    />

    <!-- Resend digital goods modal -->
    <b-modal
      v-model="resendModalVisible"
      title="Resend Email"
      ok-title="Send email"
      cancel-variant="white"
      :ok-disabled="!digitalGoodsEmail || !orderNumber"
      @ok="resendEmail"
      @hidden="orderNumber = ''"
    >
      <div
        v-if="selectedData && selectedData.item && selectedData.item.supporter"
        class="mb-50"
      >
        It will resend the order notification email to the email below.
      </div>

      <hr>

      <b-form-group label="Email address*">
        <b-form-input v-model="digitalGoodsEmail" />
      </b-form-group>

      <b-form-group label="Order Number*">
        <b-form-input
          v-model="orderNumber"
          placeholder="GXXXXXXXXXXXX"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      v-model="processingFeeModalVisible"
      title="Processing Fee"
      hide-footer
      size="lg"
    >
      <div
        v-if="selectedData && selectedData.item && selectedData.item.fee"
        class="mb-50"
      >
        <b-table-simple class="mb-4">
          <b-tbody>
            <b-tr
              v-for="(item,index) in processingFeeField"
              :key="`fee-${index}`"
            >
              <b-th
                width="50%"
              >
                {{ item.label }}
              </b-th>
              <b-td>
                <span v-if="item.key !== 'currency_as_charged' && selectedData.item.fee[item.key]">
                  {{ selectedData.item.fee.currency_as_charged }}
                </span>
                <span v-if="item.key !== 'currency_as_charged'">
                  {{ selectedData.item.fee[item.key] || 'N/A' }}
                </span>

                <div
                  v-if="item.key === 'price_as_charged_fee'"
                  class="mt-1"
                >
                  Breakdown :

                  <b-table-simple>
                    <b-tbody>
                      <b-tr class="p-0">
                        <b-th class="p-0">
                          Conversion Fee
                        </b-th>
                        <b-td>
                          {{ selectedData.item.fee.price_as_charged_conversion_fee || 'N/A' }}
                        </b-td>
                      </b-tr>
                      <b-tr class="p-0">
                        <b-th class="p-0">
                          Applied Fee
                        </b-th>
                        <b-td>
                          {{ selectedData.item.fee.price_as_charged_applied_fee || 'N/A' }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>

                <div v-if="item.key === 'currency_as_charged'">
                  <p class="font-weight-bold">
                    Currency as Charged: {{ selectedData.item.fee.currency_as_charged }}
                  </p>
                  <p class="font-weight-bold">
                    Currency as Charged Provider Fee: {{ selectedData.item.fee.currency_as_charged_provider_fee || 'N/A' }}
                  </p>
                  <p class="font-weight-bold">
                    Currency as Charged Tax Fee: {{ selectedData.item.fee.currency_as_charged_tax_fee || 'N/A' }}
                  </p>

                  <template
                    v-if="selectedData.item.fee.currency_as_charged !== selectedData.item.fee.currency_as_charged_provider_fee && selectedData.item.fee.currency_as_charged_provider_fee !== null"
                  >
                    <span class="font-weight-bold">
                      Exchange rate for Provider Fee:
                    </span>
                    <p class="font-weight-bold">
                      USD TO {{ selectedData.item.fee.currency_as_charged_provider_fee }} : {{ selectedData.item.fee.exchange_rate_snapshot[`usd_to_${selectedData.item.fee.currency_as_charged_provider_fee.toLowerCase()}`] || 'N/A' }}
                    </p>
                  </template>

                  <template
                    v-if="selectedData.item.fee.currency_as_charged !== selectedData.item.fee.currency_as_charged_tax_fee && selectedData.item.fee.currency_as_charged_tax_fee !== null"
                  >
                    <span class="font-weight-bold">
                      Exchange rate for Tax Fee:
                    </span>
                    <p class="font-weight-bold">
                      USD TO {{ selectedData.item.fee.currency_as_charged_tax_fee }} : {{ selectedData.item.fee.exchange_rate_snapshot['usd_to_' + selectedData.item.fee.currency_as_charged_tax_fee.toLowerCase()] || 'N/A' }}
                    </p>
                  </template>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BFormInput,
  BFormGroup,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  components: {
    Table,
    BFormInput,
    BFormGroup,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,
      resendModalVisible: false,
      processingFeeModalVisible: false,

      digitalGoodsEmail: '',
      orderNumber: '',

      selectedData: '',

      // Tabs
      tabOptions: [
        {
          key: 'statuses', label: 'All', default: true,
        },
        { key: 'statuses', label: 'Created', value: 'CREATED' },
        {
          key: 'statuses', label: 'Succeeded', value: 'SUCCEED',
        },
        { key: 'statuses', label: 'Cancelled', value: 'CREATED_CANCELLED' },
        { key: 'statuses', label: 'Failed', value: 'FAILED' },
      ],

      // Sort
      sortOptions: [
        {
          key: 'start_date', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'start_date', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view', 'download', {
        type: 'other',
        children: [
          {
            label: 'Processing Fee',
            event: this.processingFeeDetail,
            visibility: {
              callback: data => data.fee,
            },
          },
          {
            label: 'Resend email',
            event: this.requestResendEmail,
            visibility: {
              callback: data => data.usecase === 'DIGITAL_GOODS' && this.canAccess('manage', 'DigitalGoods'),
            },
          },
          {
            label: 'Open in Stripe',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['stripe', 'stripe_paynow', 'stripe_promptpay', 'stripe_google_play', 'stripe_applepay', 'stripe_wechat_pay'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in senangPay',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['senangpay'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in Paypal',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && data.payment_id && ['paypal'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in dragonpay',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && ['dragonpay_gcash'].includes(data.payment_src.toLowerCase()),
            },
          },
          {
            label: 'Open in Xendit',
            event: this.openPaymentGateway,
            visibility: {
              callback: data => data.payment_src && ['xendit'].includes(data.payment_src.toLowerCase()),
            },
          },
        ],
      }],

      processingFeeField: [
        {
          key: 'price_as_charged_original',
          label: 'Original Charged Price',
        },
        {
          key: 'price_as_charged_fee',
          label: 'Total Fee',
        },
        {
          key: 'price_as_charged_provider_fee',
          label: 'Gateway Fee',
        },
        {
          key: 'price_as_charged_tax_fee',
          label: 'Gateway Tax Fee',
        },
        {
          key: 'price_as_charged',
          label: 'Total Charged Price',
        },
        {
          key: 'currency_as_charged',
          label: 'Exchange Rate',
        },
      ],
    }
  },
  methods: {
    requestResendEmail(row) {
      this.selectedData = row
      this.resendModalVisible = true
      this.digitalGoodsEmail = row.item.supporter.email
    },
    resendEmail() {
      return this.$store.dispatch('transactionV2/resendEmail', {
        humanId: this.selectedData.item.human_id,
        email: this.digitalGoodsEmail,
        orderNumber: this.orderNumber,
      })
    },
    openPaymentGateway(row) {
      const paymentType = row.item.payment_src.toLowerCase()
      const paymentId = row.item.payment_id

      if (['stripe', 'stripe_paynow', 'stripe_promptpay', 'stripe_google_play', 'stripe_applepay', 'stripe_wechat_pay'].includes(paymentType)) {
        // Stripe has the same URL format for both staging and live
        window.open(`https://dashboard.stripe.com/payments/${paymentId}`)
      } else if (paymentType === 'senangpay') {
        // SenangPay probably has the same URL format for both staging and live
        window.open(`https://app.senangpay.my/payment/receipt/${paymentId}`)
      } else if (paymentType === 'paypal') {
        window.open(`https://www.paypal.com/activity/payment/${paymentId}`)
      } else if (paymentType === 'dragonpay_gcash') {
        // dragonpay doesnt have a transaction details page, all links are the same
        window.open('https://gw.dragonpay.ph/AdminWeb/Main.aspx')
      } else if (paymentType === 'xendit') {
        const trimmedPaymentId = paymentId.replace(/-/g, '')
        window.open(`https://dashboard.xendit.co/payment-links/single/${trimmedPaymentId}`)
      }
    },
    processingFeeDetail(row) {
      this.selectedData = row
      this.processingFeeModalVisible = true
    },
    loadData() {
      if (this.$route.name === 'subscription-transaction') {
        return this.$store.dispatch('transactionV2/getSubscriptionTransactionTableData', this.$route.params.id)
      }

      return this.$store.dispatch('transactionV2/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('transactionV2/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('transactionV2/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('transactionV2/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('transactionV2/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('transactionV2/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('transactionV2/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('transactionV2/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('transactionV2/SET_TABLE_KEYWORD', val)
    },
    downloadData() {
      return this.$store.dispatch('transactionV2/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
